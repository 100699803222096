import React, { useContext } from "react";
import styles from "./styles.module.scss";
import { HeroProps } from "./types";
import CustomButtons from "@components/shared/Button";
import parser from "html-react-parser";
import { AppContext } from "@src/libs/context";
import { useWindowResize } from "@src/utilities/hooks/useWindowResize";
import Link from "next/link";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import ContactUsModal from "../HaggleModal/HaggleModal";
import { Modal } from "antd";

const Hero = ({
  image,
  mobileImage,
  heading,
  subHeading,
  linkLabel,
  linkLabelMobile,
  linkUrl,
  AllowedUsers,
  promotionID,
}: HeroProps): JSX.Element => {
  const router = useRouter();
  const isMovePage = router?.asPath === "/move" ? true : false;
  const { guestUser } = useContext<any>(AppContext);
  const [openModal, setOpenModal] = useState(false);
  const isMobile = useWindowResize() <= 576;
  const isTablet = useWindowResize() <= 768;
  const [isGuestUser, setIsGuestUser] = useState<boolean | undefined>(
    undefined
  );
  const [isSignUpModalVisible, setIsSignUpModalVisible] = useState(false);

  const showSignUpModal = () => {
    setIsSignUpModalVisible(true);
  };

  const handleSignUpModalCancel = () => {
    setIsSignUpModalVisible(false);
  };

  const checkIfGuest =
    (isGuestUser && AllowedUsers === "Registered") ||
    (!isGuestUser && AllowedUsers === "Guest");
  useEffect(() => {
    setIsGuestUser(guestUser);
  }, [guestUser]);

  return (
    <>
      {typeof checkIfGuest !== "undefined" && !checkIfGuest ? (
        <div
          className={`${
            router?.asPath === "/move" ? styles.moveHeroContainer : ""
          } ${styles.heroContainer}`}
        >
          <div>
            <img
              className={styles.heroImage}
              src={isTablet ? mobileImage?.url : image?.url}
              alt={isTablet ? mobileImage?.altText : image?.altText}
            />
            <div
              className={`${[styles.heroContentContainer].join(" ")} ${
                router?.asPath === "/move"
                  ? styles.moveHeroContentContainer
                  : ""
              }`}
            >
              <div className="container">
                <h2
                  className={[
                    styles.heading,
                    isMobile ? "font-bolder-lg" : "font-black-xxxl",
                  ].join(" ")}
                >
                  {parser(heading || "")}
                </h2>
                <h3
                  className={[
                    "mb-10 font-regular-",
                    isMobile ? "base" : "h5",
                  ].join("")}
                >
                  {parser(subHeading || "")}
                </h3>

                <Link href={isMovePage ? "" : linkUrl}>
                  <a
                    onClick={(event: any) => {
                      event.preventDefault();
                      if (isMovePage) {
                        setOpenModal(true);
                      } else {
                        showSignUpModal();
                      }
                    }}
                  >
                    <CustomButtons className={styles.linkBtn} theme="primary">
                      {isMobile ? linkLabelMobile || linkLabel : linkLabel}
                    </CustomButtons>
                  </a>
                </Link>
              </div>
              <ContactUsModal
                title="Contact Us"
                subTitle="Let us share how Move can unlock your logistics"
                isModalOpen={openModal}
                setIsModalOpen={setOpenModal}
              />
              <Modal
                open={isSignUpModalVisible}
                onCancel={handleSignUpModalCancel}
                footer={null}
                width="40%"
                style={{ top: 20 }}
                bodyStyle={{ overflow: "auto", maxHeight: "70vh", display: "flex", justifyContent: "center", alignItems: "center" }}
              >
                <iframe
                  src="https://share.hsforms.com/1qGdz9GjYTf-3jraATRlbkwdasgk"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  scrolling="yes"
                  style={{ minHeight: "500px", height: "70vh", width: "100%" }}
                ></iframe>
              </Modal>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Hero;
